<template>
  <div class="legal-notice" v-if="contentJson">
    <Card :digit="contentJson.digit">
      <div class="legal-notice__content card__inner">
        <TickerHeadline>{{ contentJson.headline }}</TickerHeadline>
        <!--<div class="legal-notice__html" v-html="contentJson.content"></div>-->
        <div class="legal-notice__html">
          <p>Responsible according to § 5 TMG:<br><br>Marc Storch<br>Kopernikusstr. 17<br>10245 Berlin <br></p>
          <h4>CONTACT</h4>
          <p><a href='mailto:hello@marcstorch.com'>hello@marcstorch.com</a><br></p>
          <h4>VAT ID number</h4>
          <p>VAT ID number according to § 27 a Value Added Tax Act: DE288541244.</p>

          <h2>DISCLAIMER</h2>
          <h4>Contents of the website</h4>
          <p>The contents of this website were written with due diligence and by the author’s best knowledge. We can be held liable only by general laws, especially for our own contents acc. § 7 TMG (German law on tele-media) and for external contents acc. §§ 8 – 10 TMG. As a Provider of tele-media we can be held liable for external contents only once we have knowledge of a concrete infringement of law. We reserve the right to change or delete contents of this webpage which are not subject to any contractual obligation.</p>

          <h4>Links on external Websites</h4>
          <p>Contents of external websites on which we are linking direct or indirect (through „hyperlinks“ or „deeplinks“) are beyond our responsibility and are not adopted as our own content. When the links were published, we didn’t have knowledge of any illegal activities or contents on these websites. Since we do not have any control on the contents of these websites, we distance ourselves from all contents of all linked websites, which were updated after the setting of the links. For all contents and especially damages, resulting of the use of the linked websites, only the provider of these linked websites can be held liable. If we receive knowledge of illegal contents on these linked websites, we will delete the according links.</p>

          <h4>Intellectual property rights</h4>
          <p>All content of this website, especially texts, pictures, images, graphical presentations, music, trademarks, brands and so forth, are subject to copyright laws. The use, reproduction and so on are subject to the individual rights of the respective owner of the copyright or administrator of these rights. If you want to use such content, please let us know and we will establish contact with the respective owner/administrator.</p>
          <h4>Google Analytics</h4>
          <p>This website uses Google Analytics, a web analytics service provided by Google, Inc. (“Google”). Google Analytics uses “cookies”, which are text files placed on your computer, to help the website analyze how users use the site. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States. In case of activation of the IP anonymization, Google will truncate/anonymize the last octet of the IP address for Member States of the European Union as well as for other parties to the Agreement on the European Economic Area. Only in exceptional cases, the full IP address is sent to and shortened by Google servers in the USA. On behalf of the website provider Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage to the website provider. Google will not associate your IP address with any other data held by Google. You may refuse the use of cookies by selecting the appropriate settings on your browser. However, please note that if you do this, you may not be able to use the full functionality of this website. Furthermore you can prevent Google’s collection and use of data (cookies and IP address) by downloading and installing the browser plug-in available under <a href="https://tools.google.com/dlpage/gaoptout?hl=en-GB." target="_blank">https://tools.google.com/dlpage/gaoptout?hl=en-GB.</a></p>
        </div>

      </div>
    </Card>
  </div>

</template>

<script>
import Card from '@/components/Card'
import TickerHeadline from '../components/TickerHeadline'
import DataMixin from '@/mixins/DataMixin'

export default {
  name: 'LegalNotice',
  metaInfo: {
    title: 'MARCSTORCH.COM | LEGAL NOTICE'
  },
  mixins: [DataMixin],
  components: {
    Card, TickerHeadline
  },
  props: {
    project: undefined
  },
  mounted () {
    this.fetchData('legal-notice')
  }
}
</script>
